const projects = [
  {
    name: "Wilbur Ellis - Software Consultant - 2023",
    category: "Agriculture",
    desc: "In-house applications. https://www.wilburellis.com/",
    longDesc: "In-house applications.",
    logo: "wilbur-ellis.svg",
    location: "https://www.wilburellis.com/",
    images: [],
  },
  {
    name: "Entersekt - 2022/2023",
    category: "FinTech",
    desc: "In-house financial applications. https://www.entersekt.com/",
    longDesc: "In-house financial applications.",
    logo: "entersekt.png",
    location: "https://www.entersekt.com/",
    images: [],
  },
  {
    name: "Easy Equities (Purple Group) - 2022",
    category: "FinTech",
    desc: "The biggest project I've worked on to date with an active user base of over 750 000 users. EasyEquities is a collection of Financial applications to aid anyone in investments and decisions.",
    longDesc:
      "The biggest project I've worked on to date with an active user base of over 750 000 users. EasyEquities is a FinTech product aimed at aiding financial decisions. I mainly worked on the user managing system EasyID which manages accounts across all the EasyEquities apps. My main roles included fixing bugs, updating UI and implementing new features/forms or sections based off designs provided by designers. ",
    logo: "ee.png",
    location: "https://www.easyequities.co.za/",
    images: ["easyid/one.png", "easyid/two.png", "easyid/three.png"],
  },
  {
    name: "BluNova/POPIA - 2022",
    category: "Privacy",
    desc: "Blunova POPIA is an app that allows you to manage your private data that companies or services have from you. ",
    longDesc:
      "Blunova POPIA is an app that allows you to manage your private data that companies or services have from you. I designed the project architecture and built majority of the frontend for this application that connected to an external api. ",
    logo: "blunova.png",
    location: "https://popia.blts.co.za/",
    images: ["blunova/one.png", "blunova/two.png", "blunova/three.png"],
  },
  {
    name: "GEMS Sensing/ UMN (University of Minnesota) 2021/2022",
    category: "IOT",
    desc: "This is a project by GEMS and the University of Minnesota which involves interacting with sensors data.",
    longDesc:
      "This is a project by GEMS and the University of Minnesota which involves interacting with sensors data. My role was full stack on this project and involved me creating new features that interacted with large data and as well as implementing features such as user roles, authentication and architecture. ",
    logo: "gems.png",
    location: "https://iot.agroinformatics.org/",
    images: ["gems/one.png", "gems/two.png", "gems/three.png", "gems/four.png"],
  },
  {
    name: "MrPhy - 2021",
    category: "FinTech",
    longDesc:
      "MrPhy is an EasyEquities application that aims to help you with financial decisions and calculate your risks when investing in certain companies. My main role on this project was to mainly convert this desktop site into a mobile-compatible / responsive site but create seperate features that were also just specifically to be used on mobile. ",
    desc: "MrPhy is an EasyEquities application that aims to help you with financial decisions and calculate your risks when investing in certain companies.",
    logo: "mrphy.svg",
    location: "https://www.mrphy.co.za/",
    images: ["mrphy/one.png", "mrphy/two.png"],
  },
  {
    name: "Riskflow - 2021/2022/2023",
    category: "FinTech",
    desc: "Riskflow is an application designed to help individuals and businesses manage their income or profits and to view different saving strategies.",
    longDesc:
      "Riskflow is an application designed to help individuals and businesses manage their income or profits and to view different saving strategies. My main role on this application was creating API features as well as some frontend tasks. A memorable challenge of mine on this project was migrating the database to a different DBMS. ",
    logo: "riskflow.png",
    location: "https://cfo4i.app.riskflow.net/",
    images: ["riskflow/one.png", "riskflow/two.png", "riskflow/three.png"],
  },
  {
    name: "Courier (hackathon) - Won 2nd Place - 2022",
    date: "2022",
    category: "Notifications",
    desc: "My submission to the Courier Hacks hackathon that demonstrates proper use of notificaitons to an apps users using the Courier API.",
    longDesc:
      "My submission to the Courier Hacks hackathon that demonstrates proper use of notificaitons to an apps users using the Courier API. I built an app that sends its subscribers a random word every day and its description and translation in any language of their choosing.",
    location: "https://cute-ruby-pronghorn-wear.cyclic.app/",
    images: [
      "wordoftheday/one.png",
      "wordoftheday/two.png",
      "wordoftheday/three.png",
    ],
    logo: "courier.png",
  },
  {
    name: "Lynx Telematics - 2022/2023",
    category: "Transport/Data",
    longDesc: "In-house applications.",
    desc: "In-house applications.",
    logo: "lynx.png",
    images: [],
  },
  {
    name: "Algorithms",
    desc: "A web app demonstrating research on algorithms such as the Breadth First and Depth First Search algorithm.",
    longDesc:
      "A web app demonstrating research on algorithms such as the Breadth First and Depth First Search algorithm.",
    location: "https://www.ds-app.murrayobrien.me",
    images: ["algorithms/one.png", "algorithms/two.png"],
  },
  {
    name: "Angular CRUD App",
    desc: "A web app demonstrating the basic CRUD actions.",
    longDesc: "A web app demonstrating the basic CRUD actions.",
    location: "https://murrayobrien.github.io/angular-app",
    images: [
      "angularcrud/one.png",
      "angularcrud/two.png",
      "angularcrud/three.png",
      "angularcrud/four.png",
    ],
  },
];

export { projects };
