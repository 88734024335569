<template>
  <v-app>
    <!-- v-toolbar -->
    <v-app-bar app elevation="0" color="primary" dark>
      <v-btn icon class="ml-3">
        <v-badge
          bottom
          :color="$vuetify.theme.dark ? 'blue lighten-3' : 'green lighten-1'"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-avatar color="white" size="40">
            <img src="./assets/images/profile-pic.png" alt="Profile Pic" />
          </v-avatar>
        </v-badge>
      </v-btn>

      <v-toolbar-title class="font-weight-bold"
        ><span
          ><router-link
            class="text-decoration-none white--text"
            v-bind:class="{ 'body-2': $vuetify.breakpoint.xs }"
            :to="{ name: 'home' }"
            >{{ $vuetify.breakpoint.xs ? "Murray OB" : "Murray OBrien -" }}
            <span v-if="!$vuetify.breakpoint.xs" class="font-weight-light"
              >About me</span
            ></router-link
          ></span
        >
      </v-toolbar-title>

      <v-spacer></v-spacer>
      CV
      <a
        href="https://www.murrayobrien.me/assets/docs/resume.pdf"
        target="_blank"
        text
        color="white"
        class="mr-3"
        dark
      >
        <v-icon>mdi-download</v-icon>
      </a>
      <v-switch
        :label="$vuetify.theme.dark ? 'Dark Mode' : 'Light Mode'"
        class="mt-5"
        color="secondary"
        inset
        v-model="theme"
        @change="setTheme()"
      >
      </v-switch>

      <v-btn @click.stop="drawer = !drawer" text color="white" dark>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-navigation-drawer app v-model="drawer" temporary fixed right>
      <v-list nav dense>
        <v-list-item
          active-class="primary--text text--accent-4"
          :href="item.link"
          link
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title class="font-weight-bold">
            <v-icon class="pr-2 pb-1" color="primary">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import "../src/assets/scss/main.scss";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    //trigger dark mode
    theme: false,
    items: [
      { title: "Home", link: "#home", icon: "mdi-home-outline" },
      {
        title: "Experience",
        link: "#experience",
        icon: "mdi-timeline-check-outline",
      },
      { title: "Education", link: "#education", icon: "mdi-school-outline" },
      { title: "Skills", link: "#skills", icon: "mdi-tools" },
      { title: "Projects", link: "#projects", icon: "mdi-animation-outline" },
      { title: "Contact", link: "#contact", icon: "mdi-email-outline" },
    ],
  }),
  methods: {
    setTheme: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("theme", this.$vuetify.theme.dark.toString());
    },
  },
  mounted() {
    this.$vuetify.theme.dark = true;
    const theme = localStorage.getItem("theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.theme = true;
      } else {
        this.theme = false;
        this.$vuetify.theme.dark = false;
      }
    }
  },
};
</script>
