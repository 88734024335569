<style scoped>
.cursor-p {
  cursor: pointer;
}

.project-image {
  width: 70%;
}
</style>
<template>
  <div class="section">
    <div id="home">
      <h1>Introduction</h1>
      <br />
      <v-row align-sm="center">
        <v-col align-self="center" sm="12">
          <v-img
            :lazy-src="require('@/assets/images/' + 'profile-pic.png')"
            class="profile-pic"
            v-bind:class="$vuetify.theme.dark ? 'profile-pic-border-light' : ''"
            :src="require('@/assets/images/' + 'profile-pic.png')"
          ></v-img>
        </v-col>
      </v-row>
      <br />
      <p>Hello, <span class="font-weight-bold">I'm Murray.</span></p>
      <p>
        Accomplished and solutions-driven professional with hands-on experience
        in full stack web development, including both front-end and back-end
        development. Proven expertise in building new technologies, discussing
        requirements with clients, proposing solutions, ensuring pleasant user
        experience, maintaining databases, and performing quality assurance.
        Skilled in creating and optimising the performance of user-centric,
        high-impact websites.
      </p>
      <h3>
        Nationality
        <v-icon dense color="primary">mdi-map-marker-outline</v-icon>:
        <span class="font-weight-light">Irish / South African</span>
      </h3>
      <h3>
        Working remotely
        <v-icon dense color="primary">mdi-map-marker-outline</v-icon>
        <!-- <span class="font-weight-light">Near Madrid, Spain</span> -->
      </h3>
      <h3>
        Native Language :
        <span class="font-weight-light">English</span>
      </h3>
      <h3>
        Visa/Passport :
        <span class="font-weight-light">Irish</span>
      </h3>
    </div>
    <br />
    <v-divider></v-divider>
    <br />
    <h1>Summary</h1>
    <br />
    <h3>
      Experience:
      <span
        ><p class="font-weight-light d-inline">
          Corporate Web Development ({{ yearsAtMatogen() }}), freelancing (1/2
          year) and Bachelor of Science Degree
        </p></span
      >
    </h3>
    <h3>
      Education:
      <span
        ><p class="font-weight-light d-inline">
          Bachelor of Science (BSc) in Information Technology from Pearson
          Institute of Technology
        </p></span
      >
    </h3>
    <h3>
      Skills:
      <span
        ><p class="font-weight-light d-inline">
          Angular, Node.js, Nest.js, React, TypeScript, JavaScript, SCSS
        </p></span
      >
    </h3>
    <h3>
      Projects:
      <span
        ><a href="#projects" class="font-weight-light d-inline"
          >See here:</a
        ></span
      >
    </h3>
    <br />
    <v-divider></v-divider>
    <br />
    <!-- EXPERIENCE -->
    <div id="experience" data-aos-duration="1000" data-aos="fade-up">
      <h1>Experience</h1>
      <!-- MATOGEN -->
      <p class="font-weight-light">
        April 2021 - Present ({{ yearsAtMatogen() }})
      </p>
      <h3>
        <span class="font-weight-light">Full-stack Developer</span> at
        <a
          v-bind:class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
          target="_blank"
          href="https://matogen.com/home"
          >Matogen Corporate Web Development
          <span class="font-weight-light">(remote)</span></a
        >
      </h3>
      <br />
      <p>Responsiblities:</p>
      <ul>
        <li>Implementing new features both front-end and back-end.</li>
        <li>Improving existing usability features.</li>
        <li>Fixing bugs.</li>
        <li>Planning features and implementations.</li>
        <li>Setting up CI/CD environments (AWS).</li>
      </ul>
      <br />
      <v-divider style="width: 50%"></v-divider>
      <br />
      <!-- FREELANCE -->
      <p class="font-weight-light">November 2020 - April 2021 (6 months)</p>
      <h3>
        <span class="font-weight-light">Front-end developer</span> - Freelancing
      </h3>
      <br />
      <p>
        - Built a website for a construction company.
        <br />- Custom development for a company using Shopify. <br />- Built a
        tool that promoted a company and collected 5000+ emails for email
        marketing list. <br />- Built a Flutter application that reported 7 day
        weather/sea conditions such as wind speed, direction, wave height, etc.
      </p>
      <ul>
        <li>Shopify development.</li>
        <li>Wordpress development.</li>
      </ul>
    </div>
    <br />
    <v-divider></v-divider>
    <!-- <br /> -->
    <!-- ---------------------------------------- -->
    <!-- ---------- EDUCATION SECTION ----------- -->
    <!-- ---------------------------------------- -->
    <br />
    <div id="education">
      <h1>Education</h1>
      <p class="font-weight-light">2018 - 2020</p>

      <h3>Bachelor of Science in Information Technology (BSc)</h3>
      <p>Pearson Institute of Higher Education</p>

      <v-img
        style="width: 100px"
        :src="require('@/assets/images/eduvos.png')"
      />
    </div>

    <br />
    <v-divider></v-divider>
    <br />
    <!-- ---------------------------------------- -->
    <!-- ---------- PROJECTS SECTION ------------ -->
    <!-- ---------------------------------------- -->
    <div id="projects">
      <h1>Projects</h1>
      <br />
      <v-row>
        <v-card
          data-aos-duration="1000"
          data-aos="fade-up"
          class="d-flex justify-start mb-4 ml-1 mr-1 cursor-p"
          outlined
          max-width="344"
          v-for="(project, index) in projects"
          :key="index"
          @click="showModal(project)"
        >
          <v-card-text>
            <h3>{{ project.name }}</h3>
            <p class="pt-1">
              {{ project.desc }}
            </p>
            <v-chip outlined v-if="project.category">
              #{{ project.category }} </v-chip
            ><br /><br />
            <img
              v-if="project.logo"
              v-bind:src="require('@/assets/images/' + project.logo)"
              alt=""
              class="project-image"
            />
            <v-card-actions class="pt-0">
              <v-btn
                outlined
                class="mt-1 mb-1"
                color="primary"
                @click="showModal(project)"
                >see details</v-btn
              >
              <v-btn
                class="mt-1 mb-1"
                color="primary"
                text
                :href="project.location"
                target="_blank"
                >visit project</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-row>
      <br />
      <v-divider></v-divider>
      <br />
      <!-- ---------------------------------------- -->
      <!-- ------- SOFTWARE/SKILLS SECTION -------- -->
      <!-- ---------------------------------------- -->

      <div id="skills" data-aos-duration="1000" data-aos="fade-up">
        <h1>Software/Skills</h1>
        <!-- CHIPS -->
        <div>
          <v-chip
            v-for="(chip, idx) in chips"
            :key="idx"
            class="ma-2 white--text"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey darken-1'"
            >{{ chip.title }}</v-chip
          >
        </div>
        <br />
        <h3>Frontend</h3>
        <ul>
          <li>
            <span class="mark font-weight-bold">Angular + Material Design</span>
          </li>
          <li>
            <span class="mark font-weight-bold">Vue.js + Vuex + Vuetify </span>
          </li>
          <li>
            <span class="mark font-weight-bold">React + MUI</span>
          </li>
          <li>SCSS</li>
          <li>JavaScript</li>
          <li>Typescript</li>
        </ul>
        <br />
        <h3>Backend</h3>
        <ul>
          <li>Nestjs</li>
          <li>Node.js and Express</li>
          <li>Flask / Python</li>
        </ul>
        <br />
        <h3>Devops</h3>
        <ul>
          <li>Understanding of domains and servers.</li>
          <li>
            Ability to set up a domain and automatic deployment pipelines from a
            <span>Git or Bitbucket</span> repo.
          </li>
          <li>
            I've used and am familiar with AWS services such as EBS, S3,
            Cloudfront, etc.
          </li>
          <li>
            I've also used services such as <span>C-panel</span>,
            <span>Heroku</span>, Netlify and Cloudflare.
          </li>
        </ul>
        <br />
        <h3>Other</h3>
        <ul>
          <li>
            Libraries I regularly use: Axios, RXJS, NGXS, lodash, date-fns,
            material
          </li>
          <li>Agile methodology (Kanban).</li>
          <li>Relational databases, SQL and designing ERDs.</li>
          <li>Firebase/Firestore NoSQL.</li>
          <li>Docker containers and Docker scripts.</li>
        </ul>
      </div>
      <br />
      <v-divider></v-divider>
      <br />
      <!-- ------------------------ -->
      <!-- -------- other --------- -->
      <!-- ------------------------ -->
      <div data-aos-duration="1000" data-aos="fade-up">
        <h1 id="Homeid">Other</h1>
        <v-row>
          <v-col sm="12" lg="10">
            <p>
              In my free time I enjoy traveling 🌎, surfing 🏄‍♂️, kiting and
              making music 🎸. I also try to get my dose of exercise in during
              the week to help keep my body and mind healthy.
            </p>
          </v-col>
        </v-row>
      </div>
      <br />
      <v-divider></v-divider>
      <br />
      <!-- ------------------------ -->
      <!-- -------- contact --------- -->
      <!-- ------------------------ -->
      <div data-aos-duration="1000" data-aos="fade-up">
        <h1 id="contact">Contact</h1>
        <div class="mb-2 mt-4">
          <a
            class="text-decoration-none"
            href="https://www.linkedin.com/in/murray-o-brien-ab1454206"
            ><span class="font-weight-bold">Linkedin - </span>
            <v-icon color="primary">mdi-linkedin</v-icon></a
          >
        </div>
        <!-- <div class="mb-2 mt-4">
          <a class="text-decoration-none" href="#">
            <span class="font-weight-bold">Phone - </span>
            <v-icon color="primary">mdi-phone</v-icon>+34 (672) 188 191
          </a>
        </div> -->

        <a
          class="text-decoration-none"
          href="mailto:murrayobrien.dev@gmail.com"
        >
          <span class="font-weight-bold">Email - </span>
          <v-icon color="primary">mdi-email</v-icon> murrayobrien.dev@gmail.com
        </a>
      </div>
      <br /><br />
      <p class="caption float-right">
        Copyright © {{ getYear() }} Murray O'Brien. All Rights Reserved
      </p>
      <br />
    </div>

    <!-- MODAL -->
    <InfoDialog :project="project" ref="infoModal"></InfoDialog>
  </div>
</template>

<script>
import { projects } from "../models/projects";
import InfoDialog from "../components/InfoDialog.vue";

export default {
  name: "Home-view",
  components: {
    InfoDialog,
  },
  data: () => ({
    project: null,
    projects: projects,
    chips: [
      { title: "Angular" },
      { title: "Nest.js" },
      { title: "Node.js" },
      { title: "TypeScript" },
      { title: "SCSS" },
      { title: "React" },
      { title: "AWS" },
      { title: "Flask" },
      { title: "SQL" },
    ],
    dialog: false,
  }),
  methods: {
    showModal(project) {
      this.project = project;
      this.$refs.infoModal.show();
    },
    getJobYear() {
      const d = new Date();
      return d.getFullYear();
    },
    getYear() {
      const d = new Date();
      d.getFullYear();
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    yearsAtMatogen() {
      const begin = new Date("April, 1, 2021");
      const now = new Date();
      const years = (this.monthDiff(begin, now) / 12).toString().split(".")[0];
      const months = this.monthDiff(begin, now) % 12;

      return years + " years, " + months + " months";
    },
  },
};
</script>
