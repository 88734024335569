<style scoped>
.project-image {
  width: 30%;
  margin-left: 2rem;
}

.images {
  text-align: center;
  width: 80%;
  margin: auto;
}

.image {
  /* margin-left: 2rem; */
  width: 80%;
}
</style>
<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <!-- TITLE -->
      <v-card-title>
        <span class="text-h5">{{ project.name }}</span>
      </v-card-title>
      <!-- DESC -->
      <v-card-text>
        {{ project.longDesc }}
      </v-card-text>
      <!-- LINK -->
      <v-card-text v-if="project.location">
        Link:
        <span
          ><a :href="project.location" target="_blank">{{
            project.location
          }}</a></span
        >
      </v-card-text>
      <!-- CATEGORY -->
      <v-card-text class="text-subtitle-2" v-if="project.category">
        #{{ project.category }}
      </v-card-text>
      <!-- LOGO -->
      <img
        v-if="project.logo"
        v-bind:src="require('@/assets/images/' + project.logo)"
        class="project-image"
      />
      <!-- IMAGES -->
      <v-card-text v-if="project.images.length"> Images: </v-card-text>
      <div v-if="project.images.length">
        <v-carousel height="auto" hide-delimiters>
          <v-carousel-item
            class="images"
            :key="image"
            v-for="image in project.images"
            :src="require('@/assets/images/' + image)"
          >
          </v-carousel-item>
        </v-carousel>
      </div>
      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "InfoDialog",
  data: () => ({
    dialog: false,
  }),
  props: ["project"],
  methods: {
    show() {
      this.dialog = true;
    },
  },
};
</script>
